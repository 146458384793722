import React from 'react'
import { Routes, Route } from 'react-router'
import { useLocation } from "react-router-dom";

import NotFound from '../notFound/NotFound'
import TabClockTab from '../clock/TabClockTab'
import ClockAlarm from '../clock/ClockAlarm';
import CalcCalc from '../calc/CalcCalc';
import InTimer from '../timer/InTimer';
import AlarmClockNet from '../clock/AlarmClockNet';
import TimerTimer from '../timer/TimerTimer';
import TimerOnline from '../timer/TimerOnline';


const Router = (props) => {
    const location = useLocation();
    location.staticContext = props.staticContext;
    //console.log(location); 

    const seoLinks = [
        {
            'domain': 'tabclocktab.com',
            'path': '/',
            'anchorText': 'Tab Clock Tab',
            'title': 'Tab Clock Tab: Full Screen - Digital/Analog - Night mode',
            'description': 'Tab Clock Tab - exact time with seconds on the full screen. Night mode, analogue or digital view switch.'
        },
        {
            'domain': 'tabclocktab.com',
            'path': '/online-clock',
            'anchorText': 'Online Clock',
            'title': 'Online Clock: Full Screen - Digital/Analog - Night mode',
            'description': 'Online Clock - exact time in different time zones with seconds on the full screen. Night mode and other options.'
        },
        {
            'domain': 'tabclocktab.com',
            'path': '/clock-tab',
            'anchorText': 'Clock Tab',
            'title': 'Clock Tab: Full Screen - Digital/Analog - Night mode',
            'description': 'Clock Tab - exact time in different time zones with seconds on this clock tab easy to use. Night mode and other options.'
        },
        {
            'domain': 'tabclocktab.com',
            'path': '/online-clock-with-seconds',
            'anchorText': 'Online Clock with Seconds',
            'title': 'Online Clock with Seconds: Full Screen - Digital/Analog - Night mode',
            'description': 'Online Clock with Seconds - exact time with seconds on the full screen. Night mode and other options.'
        },
        {
            'domain': 'tabclocktab.com',
            'path': '/clock-with-seconds',
            'anchorText': 'Clock with Seconds',
            'title': 'Clock with Seconds: Full Screen - Digital/Analog - Night mode',
            'description': 'Clock with Seconds - exact time with seconds on the full screen. Night mode and other options.'
        },
        {
            'domain': 'tabclocktab.com',
            'path': '/digital-clock',
            'anchorText': 'Digital Clock',
            'title': 'Digital Clock: Full Screen - Digital/Analog - Night mode',
            'description': 'Digital Clock - exact time with seconds on the full screen. Night mode and other options.'
        },
        {
            'domain': 'tabclocktab.com',
            'path': '/analog-clock',
            'anchorText': 'Analog Clock',
            'title': 'Analog Clock: Full Screen - Digital/Analog - Night mode',
            'description': 'Analog Clock - exact time with seconds on the full screen. Night mode and other options.'
        },
        {
            'domain': 'tabclocktab.com',
            'path': '/time-clock',
            'anchorText': 'Time Clock',
            'title': 'Time Clock: Full Screen - Digital/Analog - Night mode',
            'description': 'Time Clock - exact time with seconds on the full screen. Night mode and other options.'
        },
        {
            'domain': 'tabclocktab.com',
            'path': '/flip-clock',
            'anchorText': 'Flip Clock',
            'title': 'Flip Clock: Full Screen - Digital/Analog - Night mode',
            'description': 'Flip Clock - exact time with seconds on the full screen. Night mode, analogue or digital view switch.'
        },
        {
            'domain': 'tabclocktab.com',
            'path': '/world-clock',
            'anchorText': 'World Clock',
            'title': 'World Clock: Full Screen - Digital/Analog - Night mode',
            'description': 'World Clock - display the world clock on a new tab - extact time in different time zones with seconds on the full screen'
        },
        {
            'domain': 'tabclocktab.com',
            'path': '/what-time-is-it',
            'anchorText': 'What Time is It',
            'title': 'What Time is It: Clock with Full Screen - Digital/Analog',
            'description': 'What Time is It - exact time with seconds on the full screen. Clock digital with other options'
        },
        {
            'domain': 'tabclocktab.com',
            'path': '/time-tab',
            'anchorText': 'Time Tab',
            'title': 'Time Tab: Full Screen - Digital/Analog - Night mode',
            'description': 'Time Tab - clock with exact time and seconds on the full screen. Night mode, and other options.'
        },
        {
            'domain': 'tabclocktab.com',
            'path': '/big-clock-online',
            'anchorText': 'Big Clock Online',
            'title': 'Big Clock Online: Full Screen - Digital/Analog - Night mode',
            'description': 'Big Clock Online - clock with exact time and seconds on the full screen. Night mode and other options.'
        },
        {
            'domain': 'tabclocktab.com',
            'path': '/clock-live-with-seconds',
            'anchorText': 'Clock Live with Seconds',
            'title': 'Clock Live with Seconds: Full Screen - World Time - Night mode',
            'description': 'Clock Live with Seconds - exact time in different time zones on clock tab live with seconds. Night mode and other options.'
        },
        {
            'domain': 'tabclocktab.com',
            'path': '/clock-live',
            'anchorText': 'Clock Live',
            'title': 'Clock Live: Full Screen - World Time Live - Night mode',
            'description': 'Clock Live - exact time in clock live tab. Night mode, world time and date for cities in all time zones.'
        },


        {
            'domain': 'clock-alarm.com',
            'path': '/',
            'anchorText': 'Clock Alarm',
            'title': 'Alarm Clock: Digital Alarm Clock Tab (ツ)_/¯ Easy to Use',
            'description': 'Alarm Clock - exact time with seconds and full screen option. Clock Tab with seconds. This is the best free online alarm clock ◝(ᵔᵕᵔ)◜'
        },
        {
            'domain': 'clock-alarm.com',
            'path': '/alarm-clock-online',
            'anchorText': 'Alarm Clock Online',
            'title': 'Alarm Clock Online: Full Screen - Current Time - Easy to use',
            'description': 'Alarm Clock Online - Free alarm clock online with snooze function and 5 alarm options. Alarm clock with large digits.'
        },
        {
            'domain': 'clock-alarm.com',
            'path': '/online-clock',
            'anchorText': 'Online Clock',
            'title': 'Online Clock: Full Screen - Current Time - Alarm Clock',
            'description': 'Online Clock - Free online clock with alarm and snooze function. Alarm clock with night mode.'
        },
        {
            'domain': 'clock-alarm.com',
            'path': '/set-alarm',
            'anchorText': 'Set Alarm',
            'title': 'Set Alarm: Clock Tab - Current Time - Set up to 5 alarm options',
            'description': 'Set Alarm - Free online clock with alarm and snooze function. Alarm clock with night mode.'
        },
        {
            'domain': 'clock-alarm.com',
            'path': '/set-alarm-clock',
            'anchorText': 'Set Alarm Clock',
            'title': 'Set Alarm Clock: Free Online Alarm Clock (ツ)_/¯ Easy to Use',
            'description': 'Set Alarm Clock simple and easy. Free online clock with alarm and snooze function. Clock Tab with seconds.'
        },
        {
            'domain': 'clock-alarm.com',
            'path': '/loud-alarm-clock',
            'anchorText': 'Loud Alarm Clock',
            'title': 'Loud Alarm Clock: Free Online Alarm Clock 📢 Easy to Use',
            'description': 'Loud Alarm Clock really noisy.📢 Free online clock with alarm and snooze function. Clock Tab with seconds.'
        },        


        {
            'domain': 'calc-calc.com',
            'path': '/',
            'anchorText': 'Calc',
            'title': 'Calc: Online Calculator - Simple calculator (ツ)_/¯ Easy to Use',
            'description': 'Calc - Normal calculator for simple calculations. Free and Easy to use.'
        },
        {
            'domain': 'calc-calc.com',
            'path': '/calculator-online',
            'anchorText': 'Calculator Online',
            'title': 'Calculator Online: Simple calculator (ツ)_/¯ Easy to Use',
            'description': 'Calculator Online - Free calculator online it\'s a normal calculator for various uses.'
        },
        {
            'domain': 'calc-calc.com',
            'path': '/loan-calculator',
            'anchorText': 'Loan Calculator',
            'title': 'Loan Calculator: Simple calculator (ツ)_/¯ Easy to Use',
            'description': 'Loan Calculator - Free tool to help you with calculations.'
        },
        {
            'domain': 'calc-calc.com',
            'path': '/tax-calculator',
            'anchorText': 'Tax Calculator',
            'title': 'Tax Calculator: Simple calculator (ツ)_/¯ Easy to Use',
            'description': 'Tax Calculator - Free tool to help you with calculations.'
        },
        {
            'domain': 'calc-calc.com',
            'path': '/mortgage-calculator',
            'anchorText': 'Mortgage Calculator',
            'title': 'Mortgage Calculator: Simple calculator (ツ)_/¯ Easy to Use',
            'description': 'Mortgage Calculator - Free tool to help you with calculations.'
        },
        {
            'domain': 'calc-calc.com',
            'path': '/calculadora/es',
            'anchorText': 'Calculadora En Línea',
            'title': 'Calculadora En Línea: Una calculadora sencilla (ツ)_/¯ Fácil de usar',
            'description': 'Calculadora En Línea - Herramienta gratuita para ayudarte con los cálculos.',
            'textBody': 'Siempre es así, cuando necesitamos algo nunca lo tenemos cerca, lo que a veces acaba siendo muy frustrante. ¡¿Quién no necesita o necesita una calculadora en algún momento?! Vaya... ¡Ahora existe! La mejor y más completa Calculadora es simple, rápida y fácil de usar, siempre disponible cuando la necesitas.',
            'urlToSend': 'https://textcaseconvert.com/calculator/es'
        },
        {
            'domain': 'calc-calc.com',
            'path': '/calculadora/pt',
            'anchorText': 'Calculadora Online',
            'title': 'Calculadora Online: Uma calculadora simples (ツ)_/¯ Fácil de usar',
            'description': 'Calculadora Online - Ferramenta gratuita para ajudá-lo com cálculos.',
            'textBody': 'É sempre assim, quando precisamos de algo nunca temos por perto, o que às vezes acaba sendo muito frustrante. Quem não precisa ou precisou de uma calculadora em algum momento?! Nossa... Agora existe! A melhor e mais completa Calculadora é simples, rápida e fácil de usar, sempre disponível quando você precisar.',
            'urlToSend': 'https://textcaseconvert.com/calculator/pt'
        },
        {
            'domain': 'calc-calc.com',
            'path': '/final-calculator',
            'anchorText': 'Final Calculator',
            'title': 'Final Calculator: Simple final calculator (ツ)_/¯ Easy to Use',
            'description': 'Final Calculator - Free tool to help you with calculations.'
        },        
        {
            'domain': 'calc-calc.com',
            'path': '/percent-calculator',
            'anchorText': 'Percent Calculator',
            'title': 'Percent Calculator: Simple percent calculator (ツ)_/¯ Easy to Use',
            'description': 'Percent Calculator - Free tool to help you with calculations.'
        },        
        {
            'domain': 'calc-calc.com',
            'path': '/age-calculator',
            'anchorText': 'Age Calculator',
            'title': 'Age Calculator: Simple age calculator (ツ)_/¯ Easy to Use',
            'description': 'Age Calculator - Free tool to help you with calculations.'
        },        

        {
            'domain': 'intimer.net',
            'path': '/',
            'anchorText': 'Online Timer',
            'title': 'Online Timer: Full Screen Black - Night mode',
            'description': 'Online Timer - This free timer is perfect for classrooms! Simple and easy with ready-to-use presets with option the full screen and night mode.'
        },
        {
            'domain': 'intimer.net',
            'path': '/online-countdown-timer',
            'anchorText': 'Online Countdown Timer',
            'title': 'Online Countdown Timer: Ready-to-use Presets - Full Screen Black',
            'description': 'A Free Countdown Timer - This timer is perfect for study and classroom! Keep focus! Simple and easy to use.'
        },
        {
            'domain': 'intimer.net',
            'path': '/free-timer-online',
            'anchorText': 'Free Timer Online',
            'title': 'Free Timer Online: Ready-to-use Presets - Full Screen Black',
            'description': 'Free Timer Online - This free timer online is perfect for study and classroom! Keep focus! With Black Full Screen option. Simple and easy to use.'
        },
        {
            'domain': 'intimer.net',
            'path': '/online-timer-clock',
            'anchorText': 'Online Timer Clock',
            'title': 'Online Timer Clock: Full Screen Black - Night mode - Easy to use',
            'description': 'Online Timer Clock - This free online timer clock is perfect for study and classroom! Keep focus! Simple and easy to use.'
        },
        {
            'domain': 'intimer.net',
            'path': '/online-timer-for-kids',
            'anchorText': 'Online Timer for Kids',
            'title': 'Online Timer for Kids: Full Screen Black - Easy to use',
            'description': 'Online Timer for Kids - This free online timer for kids is perfect for study and classroom! Keep focus! Simple and easy to use.'
        },
        {
            'domain': 'intimer.net',
            'path': '/online-timer-10-minutes',
            'anchorText': 'Online Timer 10 Minutes',
            'title': 'Online Timer 10 Minutes: Full Screen Black - Ready-to-use Presets',
            'description': 'Online Timer 10 Minutes - This online timer 10 minutes is perfect for study and classroom! Timer focus! With Black Full Screen option and others presets. Simple and easy to use.'
        },
        {
            'domain': 'intimer.net',
            'path': '/online-timer-with-alarm',
            'anchorText': 'Online Timer with Alarm',
            'title': 'Online Timer with Alarm: Full Screen Black - Easy to use',
            'description': 'Online Timer with Alarm - This free online timer with alarm is perfect for study and classroom! Keep focus! Simple and easy to use.'
        },
        {
            'domain': 'intimer.net',
            'path': '/online-timer-black-background',
            'anchorText': 'Online Timer Black Background',
            'title': 'Online Timer Black Background: Ready-to-use Presets - Easy to use',
            'description': 'Online Timer Black Background - This online timer with black background is perfect for study and classroom! With full screen option. Keep focus! Simple and easy to use.'
        },
        {
            'domain': 'intimer.net',
            'path': '/one-minute-timer',
            'anchorText': 'One Minute Timer',
            'title': 'Online Timer: One Minute Timer - Countdown Timer',
            'description': 'Online Timer - One-click start timer with default set to one minute. Keep focus! Simple and easy to use.',
            'textBody': 'Do you need a one minute online timer? Yay... You just found it! This is the best online one minute timer with alarm, simple, fast and easy to use, always available when you need it. There are other ready-to-use presets.',
            'urlToSend': 'https://online-timer.me/en/1-minute-timer'
        },

        {
            'domain': 'alarm-clock.net',
            'path': '/',
            'anchorText': 'Online Alarm Clock',
            'title': 'Online Alarm Clock [Free]',
            'description': 'Online Alarm Clock is a web tool that lets you set alarms for events.'
        },
        {
            'domain': 'alarm-clock.net',
            'path': '/alarm-clock-online',
            'anchorText': 'Alarm Clock Online',
            'title': 'Alarm Clock Online: Free - Current Time - Easy to use',
            'description': 'Alarm Clock Online - Free alarm clock online with snooze function and 5 alarm options. Alarm clock with large digits.'
        },
        {
            'domain': 'alarm-clock.net',
            'path': '/online-clock',
            'anchorText': 'Online Clock',
            'title': 'Online Clock: Full Screen - Current Time - Alarm Clock',
            'description': 'Online Clock - Free online clock with alarm and snooze function. Alarm clock with night mode.'
        },
        {
            'domain': 'alarm-clock.net',
            'path': '/set-alarm',
            'anchorText': 'Set Alarm',
            'title': 'Set Alarm: Clock Tab - Current Time - Set up to 5 alarm options',
            'description': 'Set Alarm - Free online clock with alarm and snooze function. Alarm clock with night mode.'
        },
        {
            'domain': 'alarm-clock.net',
            'path': '/set-alarm-clock',
            'anchorText': 'Set Alarm Clock',
            'title': 'Set Alarm Clock: Free Online Alarm Clock - Easy to Use',
            'description': 'Set Alarm Clock simple and easy. Free online clock with alarm and snooze function. Clock Tab with seconds.'
        },
        {
            'domain': 'alarm-clock.net',
            'path': '/loud-alarm-clock',
            'anchorText': 'Loud Alarm Clock',
            'title': 'Loud Alarm Clock: Free Online Alarm Clock - Easy to Use',
            'description': 'Loud Alarm Clock really noisy. Free online clock with alarm and snooze function. Clock Tab with seconds.'
        },

        {
            'domain': 'timertimer.net',
            'path': '/',
            'anchorText': 'Online Timer',
            'title': 'Online Timer [Free]',
            'description': 'A Free Online Timer - This timer is perfect for classrooms! Simple and easy with ready-to-use presets.'
        },
        {
            'domain': 'timertimer.net',
            'path': '/online-countdown-timer',
            'anchorText': 'Online Countdown Timer',
            'title': 'Online Countdown Timer - Ready-to-use Presets',
            'description': 'A Free Countdown Timer - This timer is perfect for study and classroom! Keep focus! Simple and easy to use.'
        },
        {
            'domain': 'timertimer.net',
            'path': '/free-timer-online',
            'anchorText': 'Free Timer Online',
            'title': 'Free Timer Online - Ready-to-use Presets',
            'description': 'Free Timer Online - This free timer online is perfect for study and classroom! Keep focus! Simple and easy to use.'
        },
        {
            'domain': 'timertimer.net',
            'path': '/online-timer-clock',
            'anchorText': 'Online Timer Clock',
            'title': 'Online Timer Clock - FREE - Easy to use',
            'description': 'Online Timer Clock - This free online timer clock is perfect for study and classroom! Keep focus! Simple and easy to use.'
        },
        {
            'domain': 'timertimer.net',
            'path': '/online-timer-for-kids',
            'anchorText': 'Online Timer for Kids',
            'title': 'Online Timer for Kids - FREE - Easy to use',
            'description': 'Online Timer for Kids - This free online timer for kids is perfect for study and classroom! Keep focus! Simple and easy to use.'
        },
        {
            'domain': 'timertimer.net',
            'path': '/online-timer-10-minutes',
            'anchorText': 'Online Timer 10 Minutes',
            'title': 'Online Timer 10 Minutes - FREE - Ready-to-use Presets',
            'description': 'Online Timer 10 Minutes - This free online timer 10 minutes is perfect for study and classroom! Keep focus! Simple and easy to use.'
        },
        {
            'domain': 'timertimer.net',
            'path': '/online-timer-with-alarm',
            'anchorText': 'Online Timer with Alarm',
            'title': 'Online Timer with Alarm - FREE - Easy to use',
            'description': 'Online Timer with Alarm - This free online timer with alarm is perfect for study and classroom! Keep focus! Simple and easy to use.'
        },
        {
            'domain': 'timertimer.net',
            'path': '/online-timer-black-background',
            'anchorText': 'Online Timer Black Background',
            'title': 'Online Timer Black Background [Free] Easy to use',
            'description': 'Online Timer Black Background - This free online timer with black background is perfect for study and classroom! With full screen option. Keep focus! Simple and easy to use.'
        },
        {
            'domain': 'timertimer.net',
            'path': '/1-minute-timer',
            'anchorText': 'One Minute Timer',
            'title': '1 Minute Timer [Free]',
            'description': 'Online Timer - Free one-click start timer with default set to 1 minute. Keep focus! Simple and easy to use.',
            'textBody': 'Do you need a 1 minute online timer? Yay... You just found it! This is the best online 1 minute timer with alarm, simple, fast and easy to use, always available when you need it. There are other ready-to-use presets.',
            'urlToSend': 'https://online-timer.me/en/1-minute-timer'
        },
        {
            'domain': 'timertimer.net',
            'path': '/timer-full-screen-black',
            'anchorText': 'Online Timer Full Screen Black',
            'title': 'Online Timer Full Screen Black [Free]',
            'description': 'Online Timer Full Screen Black - This free online timer with full screen option and black background is perfect for presentation! With ready-to-use presets. Simple and easy to use.'
        },

        {
            'domain': 'timeronline.net',
            'path': '/',
            'anchorText': 'Online Timer',
            'title': 'Online Timer: Full Screen Black - Night mode',
            'description': 'Online Timer - This free timer is perfect for classrooms! Simple and easy with ready-to-use presets with option the full screen and night mode.'
        },
        {
            'domain': 'timeronline.net',
            'path': '/countdown-timer',
            'anchorText': 'Online Countdown Timer',
            'title': 'Online Countdown Timer: Ready-to-use Presets - Full Screen Black',
            'description': 'A Free Countdown Timer - This timer is perfect for study and classroom! Keep focus! Simple and easy to use.'
        },
        {
            'domain': 'timeronline.net',
            'path': '/free-timer-online',
            'anchorText': 'Free Timer Online',
            'title': 'Free Timer Online: Ready-to-use Presets - Full Screen Black',
            'description': 'Free Timer Online - This free timer online is perfect for study and classroom! Keep focus! With Black Full Screen option. Simple and easy to use.'
        },



    ];

    return (
        <React.Fragment>
            <Routes>
                {seoLinks.map((seo, idx) => {
                    let host = ((typeof window !== 'undefined') ? window.location.hostname : props.host).replace('www.', '');
                    if (host === 'tabclocktab.com') {
                        let selectSeoLinks = seoLinks.filter((seo) => seo.domain === host)
                        if (idx === 0) {
                            return <Route key={idx} exact path='/' element={<TabClockTab {...props} location={location} seoLinks={selectSeoLinks} />} />
                        }
                        return <Route key={idx} path={seo.path} element={<TabClockTab {...props} location={location} seoLinks={selectSeoLinks} />} />
                    }
                    if (host === 'clock-alarm.com') {
                        let selectSeoLinks = seoLinks.filter((seo) => seo.domain === host)
                        if (idx === 0) {
                            return <Route key={idx} exact path='/' element={<ClockAlarm {...props} location={location} seoLinks={selectSeoLinks} />} />
                        }
                        return <Route key={idx} path={seo.path} element={<ClockAlarm {...props} location={location} seoLinks={selectSeoLinks} />} />
                    }
                    if (host === 'calc-calc.com') {
                        let selectSeoLinks = seoLinks.filter((seo) => seo.domain === host)
                        if (idx === 0) {
                            return <Route key={idx} exact path='/' element={<CalcCalc {...props} location={location} seoLinks={selectSeoLinks} />} />
                        }
                        return <Route key={idx} path={seo.path} element={<CalcCalc {...props} location={location} seoLinks={selectSeoLinks} />} />
                    }
                    if (host === 'intimer.net') {
                        let selectSeoLinks = seoLinks.filter((seo) => seo.domain === host)
                        if (idx === 0) {
                            return <Route key={idx} exact path='/' element={<InTimer {...props} location={location} seoLinks={selectSeoLinks} />} />
                        }
                        return <Route key={idx} path={seo.path} element={<InTimer {...props} location={location} seoLinks={selectSeoLinks} />} />
                    }
                    if (host === 'alarm-clock.net') {
                        let selectSeoLinks = seoLinks.filter((seo) => seo.domain === host)
                        if (idx === 0) {
                            return <Route key={idx} exact path='/' element={<AlarmClockNet {...props} location={location} seoLinks={selectSeoLinks} />} />
                        }
                        return <Route key={idx} path={seo.path} element={<AlarmClockNet {...props} location={location} seoLinks={selectSeoLinks} />} />
                    }
                    if (host === 'timertimer.net') {
                        let selectSeoLinks = seoLinks.filter((seo) => seo.domain === host)
                        if (idx === 0) {
                            return <Route key={idx} exact path='/' element={<TimerTimer {...props} location={location} seoLinks={selectSeoLinks} />} />
                        }
                        return <Route key={idx} path={seo.path} element={<TimerTimer {...props} location={location} seoLinks={selectSeoLinks} />} />
                    }
                    if (host === 'timeronline.net') {
                        let selectSeoLinks = seoLinks.filter((seo) => seo.domain === host)
                        if (idx === 0) {
                            return <Route key={idx} exact path='/' element={<TimerOnline {...props} location={location} seoLinks={selectSeoLinks} />} />
                        }
                        return <Route key={idx} path={seo.path} element={<TimerOnline {...props} location={location} seoLinks={selectSeoLinks} />} />
                    }

                    return ''
                })}
                <Route path='*' element={<NotFound {...props} />} />
            </Routes>
        </React.Fragment>
    )
}

export default Router